export const valueDoesExist = (value) => {
    return !(value === false || value === "" || value === null || value === undefined);
}

export const validateEmail = (email) => {
    let re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    return re.test(email);
}

export const getURLParameter = (name) =>  {
    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(window.location.search) || [null, ''])[1].replace(/\+/g, '%20')) || null;
}

function toStandardTime(value) {
    if (value !== null && value !== undefined){ //If value is passed in
        if(value.indexOf('AM') > -1 || value.indexOf('PM') > -1){ //If time is already in standard time then don't format.
            return value;
        }
        else {
            if(value.length === 8){ //If value is the expected length for military time then process to standard time.
                let hour = parseInt(value.substring ( 0,2 )); //Extract hour
                const minutes = value.substring ( 3,5 ); //Extract minutes
                let identifier = 'AM'; //Initialize AM PM identifier

                if(hour === 12){ //If hour is 12 then should set AM PM identifier to PM
                    identifier = 'PM';
                }
                if(hour === 0){ //If hour is 0 then set to 12 for standard time 12 AM
                    hour=12;
                }
                if(hour > 12){ //If hour is greater than 12 then convert to standard 12 hour format and set the AM PM identifier to PM
                    hour = hour - 12;
                    identifier='PM';
                }
                return hour.toString() + ':' + minutes + ' ' + identifier; //Return the constructed standard time
            }
            else { //If value is not the expected length than just return the value as is
                return value;
            }
        }
    }
}

function checkIfTimeStampIsInSecondsAndConvert(timeStamp){
    //check if timeStamp is in seconds or milliseconds
    //Its 2020 so for now we can assume milliseconds if timestamp has 13 or more digits
    //This should work until the year 33658 so thats good
    //is milliseconds and javascript prefers milliseconds
    let stringTimeStampLength = 0;
    if(typeof timeStamp !== "string"){
        stringTimeStampLength = timeStamp.toString().length;
    } else {
        stringTimeStampLength = timeStamp.length;
        timeStamp = parseInt(timeStamp);
    }
    if(stringTimeStampLength < 13){
        //is seconds convert to milliseconds
        return new Date(timeStamp*1000)
    } else {
        return new Date(timeStamp)
    }
}

export const convertTimeStampToHumanReadable = (timeStamp) => {
    if(!timeStamp){
        return null
    }
    const dateToConvert = checkIfTimeStampIsInSecondsAndConvert(timeStamp);
    const formattedTimeSplit = dateToConvert.toString().split(" ");
    formattedTimeSplit[4] = toStandardTime(formattedTimeSplit[4]);
    //remove bullshit returned by date
    formattedTimeSplit.splice(5,4);
    //get short timezone
    formattedTimeSplit.push(dateToConvert.toLocaleString('en', {timeZoneName:'short'}).split(' ').pop());
    return formattedTimeSplit.join(" ");
};

export const sanitizeEmail = (emailAddress) => {
    //get part of email before @ sign, this has different rules then text after the @
    const firstPartOfEmail = emailAddress.split('@')[0];
    //get rest of email to recombine later
    const secondPartOfEmail = emailAddress.split('@')[1];
    //Removes periods because on some mail clients they can be moved around and we will treat them like a seperate email
    const removedPeriodsFirstPartOfEmail = firstPartOfEmail.replace(/\./g, "");
    //Check if there is a plus in the email
    const splitPluses = removedPeriodsFirstPartOfEmail.split('+');
    let finalFirstPart;
    if (splitPluses.length > 1)
    {
        //if there is a plus cut all the nonsense after the plus, this is important because some email clients allow you to put whatever you want after the plus
        finalFirstPart = splitPluses[0];
    }
    else
    {
        //if no plus continue with periods removed from first part of email
        finalFirstPart = removedPeriodsFirstPartOfEmail;
    }
    //recombine email to make the sanitized version
    const sanitizedEmail = finalFirstPart + "@" + secondPartOfEmail;
    //change to upper so people can sneak by case on me
    return sanitizedEmail.toLowerCase();
}

export const validUrl = (str) => {
    let pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
}

export const isObjectEmpty = (obj) =>  {
    for(let prop in obj) {
        if(obj.hasOwnProperty(prop)) {
            return false;
        }
    }
    return JSON.stringify(obj) === JSON.stringify({});
}
