import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { render, unmountComponentAtNode } from 'react-dom';
import { valueDoesExist } from './HelpfulFunction';

function ReactConfirmAlert({
                             title,
                             message,
                             buttons,
                             childrenElement,
                             customUI,
                             willUnmount,
                             changeTitleSize,
                             variables = {},
                             messageImage,
                           }) {
  useEffect(() => {
    return () => {
      if (willUnmount) {
        willUnmount();
      }
    };
  }, [willUnmount]);

  const handleClickButton = button => {
    if (button.onClick) button.onClick();
    close();
  }

  const close = () => {
    removeElementReconfirm();
    removeSVGBlurReconfirm();
  }

  const renderCustomUI = () => {
    const dataCustomUI = {
      title,
      message,
      onClose: close
    };

    return customUI(dataCustomUI);
  }

  return (
      <div className='react-confirm-alert-overlay'>
        <div className='react-confirm-alert'>
          {messageImage &&
              <div style={{ textAlign: "center" }}>
                <img src={messageImage} alt="" className="confirmAlertImage" />
              </div>
          }
          {valueDoesExist(title) && <h1 style={{ fontSize: changeTitleSize }}>{title}</h1>}
          {message && <h3><span dangerouslySetInnerHTML={{ __html: message }} /></h3>}
          {childrenElement()}
          <div className='react-confirm-alert-button-group'>
            {buttons.map((button, i) => (
                <button
                    style={{ backgroundColor: variables.primaryColor || "black", color: variables.secondaryColor || "white" }}
                    key={i}
                    onClick={() => handleClickButton(button)}
                >
                  {button.label}
                </button>
            ))}
          </div>
        </div>
      </div>
  );
}

ReactConfirmAlert.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  buttons: PropTypes.array.isRequired,
  childrenElement: PropTypes.func,
  customUI: PropTypes.func,
  willUnmount: PropTypes.func,
  changeTitleSize: PropTypes.number,
  variables: PropTypes.object,
  messageImage: PropTypes.string
};

ReactConfirmAlert.defaultProps = {
  buttons: [
    {
      label: 'Cancel',
      onClick: () => null
    },
    {
      label: 'Confirm',
      onClick: () => null
    }
  ],
  childrenElement: () => null,
  willUnmount: () => null
};

function createSVGBlurReconfirm() {
  const svgNS = 'http://www.w3.org/2000/svg'
  const feGaussianBlur = document.createElementNS(svgNS, 'feGaussianBlur')
  feGaussianBlur.setAttribute('stdDeviation', '0.7')

  const filter = document.createElementNS(svgNS, 'filter')
  filter.setAttribute('id', 'gaussian-blur')
  filter.appendChild(feGaussianBlur)

  const svgElem = document.createElementNS(svgNS, 'svg')
  svgElem.setAttribute('id', 'react-confirm-alert-firm-svg')
  svgElem.setAttribute('class', 'react-confirm-alert-svg')
  svgElem.appendChild(filter)

  document.body.appendChild(svgElem)
}

function removeSVGBlurReconfirm() {
  const svg = document.getElementById('react-confirm-alert-firm-svg')
  svg.parentNode.removeChild(svg)
  document.body.children[0].classList.remove('react-confirm-alert-blur')
}

function createElementReconfirm(properties) {
  document.body.children[0].classList.add('react-confirm-alert-blur')
  const divTarget = document.createElement('div')
  divTarget.id = 'react-confirm-alert'
  document.body.appendChild(divTarget)
  render(<ReactConfirmAlert {...properties} />, divTarget)
}

function removeElementReconfirm() {
  const target = document.getElementById('react-confirm-alert')
  unmountComponentAtNode(target)
  target.parentNode.removeChild(target)
}

export function confirmAlert(properties) {
  createSVGBlurReconfirm();
  createElementReconfirm(properties);
}

export function confirmAlertAsync(properties) {
  return new Promise((resolve, reject) => {
    createSVGBlurReconfirm();
    const newProps = {
      ...properties,
      buttons: properties.buttons.map(button => ({
        ...button,
        onClick: () => {
          if (button.onClick) {
            const result = button.onClick();
            resolve(result);
          }
        },
      })),
    };
    createElementReconfirm(newProps);
  });
}

export default ReactConfirmAlert;
