import React, { useMemo } from 'react'
import countryList from 'react-select-country-list'

export const CountrySelector = React.forwardRef((props, ref) => {
    const options = useMemo(() => countryList().getData(), []);
    return <select name={props.name} id={props.id} className="form-control" defaultValue={props.defaultValue} onChange={props.onChange} ref={ref} placeholder="Country" style={{borderTopLeftRadius: "0.25rem", borderBottomLeftRadius:"0.25rem"}}>
        {options.map(function (item, index) {
            return <option key={index} value={item.label}>{item.label}</option>
        })}
    </select>
})
